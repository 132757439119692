import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { forkJoin } from 'rxjs';
import { ApiService } from 'src/app/services/api.service';
import { DatepickerOptions } from 'ng2-datepicker';
import { getYear } from 'date-fns';
import locale from 'date-fns/locale/fr';
import { ChartDataSets, ChartOptions } from 'chart.js';
import { Color, Label } from 'ng2-charts';
import { Router } from '@angular/router';
import * as L from 'Leaflet';

@Component({
  selector: 'app-stats',
  templateUrl: './stats.component.html',
  styleUrls: ['./stats.component.scss']
})
export class StatsComponent implements OnInit {

  usersChartData: ChartDataSets[];
  usersChartLabels: Label[];

  vuesChartData: ChartDataSets[];
  vuesChartLabels: Label[];

  devicesChartData: ChartDataSets[];
  devicesChartLabels: Label[];

  lineChartOptions = {
    responsive: true,
    bezierCurve: false
  };

  lineChartColors: Color[] = [
    {
      borderColor: 'rgba(90, 181, 224, 1)',
      backgroundColor: 'rgba(90, 181, 224, 0.28)',
    },
  ];

  lineChartLegend = false;
  lineChartPlugins = [];
  lineChartType = 'line';

  isLoading: boolean = false;
  mapLoading: boolean = false;

  startDate: string;
  endDate: any;

  globalStats: any;

  users: any;
  vues: any;
  devices: any;

  topVues: any;
  topArticles: any;
  topRoutes: any;
  topParcours: any;

  map: any;
  markers: any = [];

  optionsDatePicker: DatepickerOptions = {
    minYear: getYear(new Date()) - 5,
    maxYear: getYear(new Date()) + 10,
    placeholder: 'Sélectionner une date',
    format: 'dd-MM-yyyy',
    formatTitle: 'LLLL yyyy',
    formatDays: 'EEEEE',
    firstCalendarDay: 1, // 0 - Sunday, 1 - Monday
    locale: locale,
    inputClass: '',
    calendarClass: 'datepicker-default',
  };

  datePickerStart: any;
  datePickerEnd: any;

  markerClusterGroup: L.MarkerClusterGroup;
  markerClusterData = [];

  constructor(private apiService: ApiService, private toastr: ToastrService, private router: Router) { }

  ngOnInit(): void {
    this.initDates();
    if (this.map) {
      this.map.off();
      this.map.remove();
    }
  }

  initDates() {
    this.isLoading = true;
    this.mapLoading = true;

    let start: any = new Date();
    start.setMonth(start.getMonth() - 1);
    let end: any = new Date();

    let startYear = start.getFullYear();
    let startMonth = String(start.getMonth() + 1).padStart(2, '0');
    let startDay = String(start.getDate()).padStart(2, '0');
    let startHour = start.getHours();
    let startMinutes = start.getMinutes();
    this.startDate = startYear + '/' + startMonth + '/' + startDay + ' ' + startHour + ':' + startMinutes;

    let endYear = end.getFullYear();
    let endMonth = String(end.getMonth() + 1).padStart(2, '0');
    let endDay = String(end.getDate()).padStart(2, '0');
    let endHour = end.getHours();
    let endMinutes = end.getMinutes();
    this.endDate = endYear + '/' + endMonth + '/' + endDay + ' ' + endHour + ':' + endMinutes;

    this.datePickerStart = new Date(this.startDate);
    this.datePickerEnd = new Date(this.endDate);

    this.loadStats();
  }

  loadStats() {
    this.isLoading = true;
    let linkGlobalStats = this.apiService.getGlobalStats(this.startDate, this.endDate);
    let linkTopVues = this.apiService.getTopVues(this.startDate, this.endDate);
    let linkTopArticles = this.apiService.getTopArticles(this.startDate, this.endDate);
    let linkTopRoutes = this.apiService.getTopRoutes(this.startDate, this.endDate);
    let linkTopParcours = this.apiService.getTopParcours(this.startDate, this.endDate);

    let linkUsers = this.apiService.getUserStats(this.startDate, this.endDate);
    let linkVues = this.apiService.getVuesStats(this.startDate, this.endDate);
    let linkDevices = this.apiService.getDevicesStats(this.startDate, this.endDate);

    forkJoin([linkGlobalStats, linkTopVues, linkTopArticles, linkTopRoutes, linkTopParcours, linkUsers, linkVues, linkDevices]).subscribe(
      results => {
        this.isLoading = false;

        this.globalStats = results[0].data;
        this.globalStats.total_users = this.globalStats.total_users.toLocaleString();
        this.globalStats.total_devices = this.globalStats.total_devices.toLocaleString();
        this.globalStats.vues = this.globalStats.vues.toLocaleString();

        this.topVues = results[1].data;
        this.topArticles = results[2].data;
        this.topRoutes = results[3].data;
        this.topParcours = results[4].data;

        this.users = results[5].data;
        this.vues = results[6].data;
        this.devices = results[7].data;
        this.initCharts();
      },
      err => {
        this.isLoading = false;
        this.toastr.error('Une erreur est survenue');
        console.log(err);
      }
    );

    this.apiService.getMapStats().subscribe(
      data => {
        this.mapLoading = false;
        this.markers = data.data;
        this.initMap();
      },
      err => {
        console.log(err);
      }
    );

  }

  initCharts() {
    this.usersChartData = [{
      data: [], 
      lineTension: 0 
    }];
    this.usersChartLabels = [];

    this.users.list.forEach(user => {
      this.usersChartData[0].data.push(user.count);
      let label: any = new Date(user.day);
      let y = label.getFullYear();
      let m = String(label.getMonth() + 1).padStart(2, '0');
      let d = String(label.getDate()).padStart(2, '0');
      label = d + '/' + m + '/' + y;
      this.usersChartLabels.push(label);
    });

    this.vuesChartData = [{
      data: [], 
      lineTension: 0 
    }];
    this.vuesChartLabels = [];

    this.vues.list.forEach(vue => {
      this.vuesChartData[0].data.push(vue.count);
      let label: any = new Date(vue.day);
      let y = label.getFullYear();
      let m = String(label.getMonth() + 1).padStart(2, '0');
      let d = String(label.getDate()).padStart(2, '0');
      label = d + '/' + m + '/' + y;
      this.vuesChartLabels.push(label);
    });

    this.devicesChartData = [{
      data: [], 
      lineTension: 0 
    }];
    this.devicesChartLabels = [];

    this.devices.list.forEach(device => {
      this.devicesChartData[0].data.push(device.count);
      let label: any = new Date(device.day);
      let y = label.getFullYear();
      let m = String(label.getMonth() + 1).padStart(2, '0');
      let d = String(label.getDate()).padStart(2, '0');
      label = d + '/' + m + '/' + y;
      this.devicesChartLabels.push(label);
    });
  }

  initMap() {
    this.map = L.map('map', {
      center: [ 48.866667, 2.333333 ],
      zoom: 2
    });

    const tiles = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      maxZoom: 18,
      minZoom: 1
    });
    tiles.addTo(this.map);

    this.markerClusterGroup = L.markerClusterGroup();
    this.markers.forEach(element => {
      if (element.last_coordinates && element.last_coordinates.lat && element.last_coordinates.lon) {
        if (!(element.last_coordinates.lat == 'NaN' || element.last_coordinates.lon == 'NaN')) {
          const marker = L.circleMarker([Number(element.last_coordinates.lat), Number(element.last_coordinates.lon)], {radius: 5});
          this.markerClusterGroup.addLayer(marker);
        }
      }
    });
    this.markerClusterGroup.addTo(this.map);
    this.map.fitBounds(this.markerClusterGroup.getBounds());
  }

  setStartDate(event) {
    let start: any = new Date(event);
    let startYear = start.getFullYear();
    let startMonth = String(start.getMonth() + 1).padStart(2, '0');
    let startDay = String(start.getDate()).padStart(2, '0');
    let startHour = start.getHours();
    let startMinutes = start.getMinutes();
    this.startDate = startYear + '/' + startMonth + '/' + startDay + ' ' + startHour + ':' + startMinutes;
    this.datePickerStart = new Date(this.startDate);
    this.loadStats();
  }

  setEndDate(event) {
    let end: any = new Date(event);
    let endYear = end.getFullYear();
    let endMonth = String(end.getMonth() + 1).padStart(2, '0');
    let endDay = String(end.getDate()).padStart(2, '0');
    let endHour = end.getHours();
    let endMinutes = end.getMinutes();
    this.endDate = endYear + '/' + endMonth + '/' + endDay + ' ' + endHour + ':' + endMinutes;
    this.datePickerEnd = new Date(this.endDate);
    this.loadStats();
  }

  onViewSingle(vue) {
    this.router.navigate(['/statistiques', vue.app_vue]);
  }

}