<div class="head">
  <div class="title">
    Quizz
  </div>
  <div class="point">
    {{point.title}}
  </div>
</div>

<div class="blocking">
  <div class="button">
    <input 
      type="checkbox" 
      id="blocking" 
      name="block" 
      (change)="toggleBlocking($event)"
      [(ngModel)]="isBlocking"
    >
    <label for="blocking">Bloquer l'accès à l'étape suivante en fonction du score</label>
  </div>
  <div class="score" *ngIf="isBlocking">
    <div class="input-title">
      Score minimum :
    </div>
    <div class="input">
      <input
        type="number"
        placeholder=""
        [(ngModel)]="minPoints"
      />
    </div>
    <div class="input-title">
      points
    </div>
  </div>
</div>

<div class="type-score" *ngIf="isBlocking">
  <div class="title">
    Ce minimum de {{ minPoints }} points, faut-il le marquer à travers :
  </div>
  <div class="button">
    <input 
      type="checkbox" 
      id="onEtape" 
      name="onEtape" 
      (change)="toggleOnEtape($event)"
      [(ngModel)]="onEtape"
    >
    <label for="onEtape">Cette étape uniquement</label>
  </div>
  <div class="button">
    <input 
      type="checkbox" 
      id="onParcours" 
      name="onParcours" 
      (change)="toggleOnParcours($event)"
      [(ngModel)]="onParcours"
    >
    <label for="onParcours">Tout le parcours</label>
  </div>
  <div class="info">
    Avant cette étape, le maximum de points récupérable sur le parcours est de {{ scoreMax }}.
  </div>
</div>

<div class="questions" [sortablejs]="quizzQuestions">
  <div class="quizz-question" *ngFor="let question of quizzQuestions; let i = index">
    <div class="head-question">
      <div class="order" *ngIf="quizzQuestions.length > 1">
        <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
          <g>
            <g>
              <path d="M507.353,245.245l-83.692-78.769c-4.289-4.039-10.57-5.141-15.98-2.803c-5.409,2.337-8.911,7.665-8.911,13.558v34.462
                h-98.462v-98.462h34.462c5.892,0,11.221-3.502,13.558-8.911c2.337-5.409,1.236-11.69-2.803-15.98L266.755,4.647
                C263.964,1.682,260.072,0,256,0s-7.964,1.682-10.755,4.647L166.476,88.34c-4.039,4.29-5.14,10.571-2.803,15.98
                c2.337,5.409,7.665,8.911,13.558,8.911h34.462v98.462h-98.462v-34.462c0-5.892-3.502-11.221-8.911-13.558
                c-5.41-2.337-11.69-1.236-15.98,2.803L4.647,245.245C1.682,248.036,0,251.928,0,256c0,4.072,1.682,7.964,4.647,10.755
                l83.692,78.769c4.29,4.039,10.57,5.141,15.98,2.803c5.409-2.337,8.911-7.665,8.911-13.558v-34.462h98.462v98.462h-34.462
                c-5.892,0-11.221,3.502-13.558,8.911c-2.337,5.409-1.236,11.69,2.803,15.98l78.769,83.692c2.79,2.966,6.683,4.647,10.755,4.647
                c4.072,0,7.964-1.682,10.755-4.647l78.769-83.692c4.039-4.29,5.14-10.571,2.803-15.98c-2.337-5.409-7.665-8.911-13.558-8.911
                h-34.462v-98.462h98.462v34.462c0,5.892,3.502,11.221,8.911,13.558c5.41,2.337,11.691,1.236,15.98-2.803l83.692-78.769
                c2.966-2.79,4.647-6.683,4.647-10.755S510.318,248.036,507.353,245.245z"/>
            </g>
          </g>
        </svg>
      </div>
      <div class="delete" (click)="onDeleteQuestion(i)">
        <svg height="427pt" viewBox="-40 0 427 427.00131" width="427pt" xmlns="http://www.w3.org/2000/svg"><path d="m232.398438 154.703125c-5.523438 0-10 4.476563-10 10v189c0 5.519531 4.476562 10 10 10 5.523437 0 10-4.480469 10-10v-189c0-5.523437-4.476563-10-10-10zm0 0"/><path d="m114.398438 154.703125c-5.523438 0-10 4.476563-10 10v189c0 5.519531 4.476562 10 10 10 5.523437 0 10-4.480469 10-10v-189c0-5.523437-4.476563-10-10-10zm0 0"/><path d="m28.398438 127.121094v246.378906c0 14.5625 5.339843 28.238281 14.667968 38.050781 9.285156 9.839844 22.207032 15.425781 35.730469 15.449219h189.203125c13.527344-.023438 26.449219-5.609375 35.730469-15.449219 9.328125-9.8125 14.667969-23.488281 14.667969-38.050781v-246.378906c18.542968-4.921875 30.558593-22.835938 28.078124-41.863282-2.484374-19.023437-18.691406-33.253906-37.878906-33.257812h-51.199218v-12.5c.058593-10.511719-4.097657-20.605469-11.539063-28.03125-7.441406-7.421875-17.550781-11.5546875-28.0625-11.46875h-88.796875c-10.511719-.0859375-20.621094 4.046875-28.0625 11.46875-7.441406 7.425781-11.597656 17.519531-11.539062 28.03125v12.5h-51.199219c-19.1875.003906-35.394531 14.234375-37.878907 33.257812-2.480468 19.027344 9.535157 36.941407 28.078126 41.863282zm239.601562 279.878906h-189.203125c-17.097656 0-30.398437-14.6875-30.398437-33.5v-245.5h250v245.5c0 18.8125-13.300782 33.5-30.398438 33.5zm-158.601562-367.5c-.066407-5.207031 1.980468-10.21875 5.675781-13.894531 3.691406-3.675781 8.714843-5.695313 13.925781-5.605469h88.796875c5.210937-.089844 10.234375 1.929688 13.925781 5.605469 3.695313 3.671875 5.742188 8.6875 5.675782 13.894531v12.5h-128zm-71.199219 32.5h270.398437c9.941406 0 18 8.058594 18 18s-8.058594 18-18 18h-270.398437c-9.941407 0-18-8.058594-18-18s8.058593-18 18-18zm0 0"/><path d="m173.398438 154.703125c-5.523438 0-10 4.476563-10 10v189c0 5.519531 4.476562 10 10 10 5.523437 0 10-4.480469 10-10v-189c0-5.523437-4.476563-10-10-10zm0 0"/></svg>
        Supprimer la question
      </div>
    </div>
    <div class="row">
      <div class="title">
        Question
      </div>
      <div class="input-title">
        Question
      </div>
      <div class="input">
        <input
          type="text"
          placeholder="Titre de la question"
          [(ngModel)]="question.title"
        />
      </div>
      <div class="col">
        <div class="input-title">
          Description
        </div>
        <div class="input">
          <editor
            [(ngModel)]="question.description"
            modelEvents="change input nodechange undo redo"
            apiKey="qu12q7xdexme5s8titxj7jsxytv1ycxye4bejzoo1gvh0ppg"
            [init]="{
              menubar: false,
              inline: true,
              plugins: [
                'autolink link quickbars'
              ],
              toolbar: false,
              entity_encoding : 'raw',
              quickbars_selection_toolbar: 'bold quicklink',
              forced_root_block : ''
            }"
          >
          </editor>
        </div>
      </div>
      <div class="col">
        <div class="custom-dropzone" ngx-dropzone [accept]="'image/*'" (change)="onSelectImgNewQuestion($event, question)">
          <ngx-dropzone-label>
            <div class="label">Glissez ou cliquez pour déposer une image à ajouter à la question</div>
          </ngx-dropzone-label>
          <ngx-dropzone-image-preview ngProjectAs="ngx-dropzone-preview" *ngFor="let f of question.newQuestionMedia" [file]="f" [removable]="true" [multiple]="false" (removed)="onRemoveImgNewQuestion(question, f)">
            <ngx-dropzone-label>{{ f.name }}</ngx-dropzone-label>
          </ngx-dropzone-image-preview>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="title">
        Type de réponse
      </div>
      <div class="input-title">
        Type de réponse
      </div>
      <div class="input">
        <select [(ngModel)]="question.quizz_type" name="types" id="type-select" (change)="changeQuizzType(i)">
          <option value="1">Réponse libre</option>
          <option value="2">QCM</option>
        </select>
      </div>
      <div *ngIf="question.quizz_type == 1" style="width: 100%">
        <div class="input-title">
          Réponse à découvrir
        </div>
        <div class="input">
          <input
            type="text"
            placeholder="Réponse à découvrir"
            [(ngModel)]="question.solution"
          />
        </div>
      </div>
      <div *ngIf="question.quizz_type == 2" style="width: 100%">
        <div class="qcm">
          <div class="options">
            <div class="sortable" [sortablejs]="question.options">
              <div class="option" *ngFor="let option of question.options; let index = index">
                <div class="input">
                  <input
                    type="text"
                    placeholder="Réponse possible"
                    [(ngModel)]="option.title"
                  />
                </div>
                <div class="order"> 
                  <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
                    <g>
                      <g>
                        <path d="M507.353,245.245l-83.692-78.769c-4.289-4.039-10.57-5.141-15.98-2.803c-5.409,2.337-8.911,7.665-8.911,13.558v34.462
                          h-98.462v-98.462h34.462c5.892,0,11.221-3.502,13.558-8.911c2.337-5.409,1.236-11.69-2.803-15.98L266.755,4.647
                          C263.964,1.682,260.072,0,256,0s-7.964,1.682-10.755,4.647L166.476,88.34c-4.039,4.29-5.14,10.571-2.803,15.98
                          c2.337,5.409,7.665,8.911,13.558,8.911h34.462v98.462h-98.462v-34.462c0-5.892-3.502-11.221-8.911-13.558
                          c-5.41-2.337-11.69-1.236-15.98,2.803L4.647,245.245C1.682,248.036,0,251.928,0,256c0,4.072,1.682,7.964,4.647,10.755
                          l83.692,78.769c4.29,4.039,10.57,5.141,15.98,2.803c5.409-2.337,8.911-7.665,8.911-13.558v-34.462h98.462v98.462h-34.462
                          c-5.892,0-11.221,3.502-13.558,8.911c-2.337,5.409-1.236,11.69,2.803,15.98l78.769,83.692c2.79,2.966,6.683,4.647,10.755,4.647
                          c4.072,0,7.964-1.682,10.755-4.647l78.769-83.692c4.039-4.29,5.14-10.571,2.803-15.98c-2.337-5.409-7.665-8.911-13.558-8.911
                          h-34.462v-98.462h98.462v34.462c0,5.892,3.502,11.221,8.911,13.558c5.41,2.337,11.691,1.236,15.98-2.803l83.692-78.769
                          c2.966-2.79,4.647-6.683,4.647-10.755S510.318,248.036,507.353,245.245z"/>
                      </g>
                    </g>
                  </svg>
                </div>
                <div class="is-answer">
                  <input 
                    type="radio" 
                    name="answer-{{i}}"
                    [value]="option.id"
                    [checked]="option.is_solution"
                    (change)="onOptionChange(i, index)"
                  >
                </div>
                <div class="delete" (click)="onDeleteOption(i, index)">
                  <svg height="329pt" viewBox="0 0 329.26933 329" width="329pt" xmlns="http://www.w3.org/2000/svg"><path d="m194.800781 164.769531 128.210938-128.214843c8.34375-8.339844 8.34375-21.824219 0-30.164063-8.339844-8.339844-21.824219-8.339844-30.164063 0l-128.214844 128.214844-128.210937-128.214844c-8.34375-8.339844-21.824219-8.339844-30.164063 0-8.34375 8.339844-8.34375 21.824219 0 30.164063l128.210938 128.214843-128.210938 128.214844c-8.34375 8.339844-8.34375 21.824219 0 30.164063 4.15625 4.160156 9.621094 6.25 15.082032 6.25 5.460937 0 10.921875-2.089844 15.082031-6.25l128.210937-128.214844 128.214844 128.214844c4.160156 4.160156 9.621094 6.25 15.082032 6.25 5.460937 0 10.921874-2.089844 15.082031-6.25 8.34375-8.339844 8.34375-21.824219 0-30.164063zm0 0"/>
                  </svg>
                </div>
              </div>
            </div>
          </div>
          <div class="add-option" (click)="onAddOption(i)">
            Ajouter une option
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="title">
        Réponse
      </div>
      <div class="input-title">
        Titre de la réponse
      </div>
      <div class="input">
        <input
          type="text"
          placeholder="Titre de la réponse"
          [(ngModel)]="question.title_answer"
        />
      </div>
      <div class="col">
        <div class="input-title">
          Description de la réponse
        </div>
        <div class="input">
          <editor
            [(ngModel)]="question.description_answer"
            modelEvents="change input nodechange undo redo"
            apiKey="qu12q7xdexme5s8titxj7jsxytv1ycxye4bejzoo1gvh0ppg"
            [init]="{
              menubar: false,
              inline: true,
              plugins: [
                'autolink link quickbars'
              ],
              toolbar: false,
              entity_encoding : 'raw',
              quickbars_selection_toolbar: 'bold quicklink',
              forced_root_block : ''
            }"
          >
          </editor>
        </div>
      </div>
      <div class="col">
        <div class="custom-dropzone" ngx-dropzone [accept]="'image/*'" (change)="onSelectImgNewAnswer($event, question)">
          <ngx-dropzone-label>
            <div class="label">Glissez ou cliquez pour déposer une image à ajouter à la réponse</div>
          </ngx-dropzone-label>
          <ngx-dropzone-image-preview ngProjectAs="ngx-dropzone-preview" *ngFor="let f of question.newAnswerMedia" [file]="f" [removable]="true" [multiple]="false" (removed)="onRemoveImgNewAnswer(question, f)">
            <ngx-dropzone-label>{{ f.name }}</ngx-dropzone-label>
          </ngx-dropzone-image-preview>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="title">
        En cas de mauvaise réponse
      </div>
      <div class="input-title">
        Titre à afficher
      </div>
      <div class="input">
        <input
          type="text"
          placeholder="Titre à afficher"
          [(ngModel)]="question.title_wrong_answer_false"
        />
      </div>
      <div class="col">
        <div class="input-title">
          Description à afficher
        </div>
        <div class="input">
          <editor
          [(ngModel)]="question.description_wrong_answer_false"
          modelEvents="change input nodechange undo redo"
          apiKey="qu12q7xdexme5s8titxj7jsxytv1ycxye4bejzoo1gvh0ppg"
          [init]="{
            menubar: false,
            inline: true,
            plugins: [
              'autolink link quickbars'
            ],
            toolbar: false,
            entity_encoding : 'raw',
            quickbars_selection_toolbar: 'bold quicklink',
            forced_root_block : ''
          }"
        >
        </editor>
        </div>
      </div>
      <div class="col">
        <div class="custom-dropzone" ngx-dropzone [accept]="'image/*'" (change)="onSelectImgFalseAnswer($event, question)">
          <ngx-dropzone-label>
            <div class="label">Glissez ou cliquez pour déposer une image à ajouter</div>
          </ngx-dropzone-label>
          <ngx-dropzone-image-preview ngProjectAs="ngx-dropzone-preview" *ngFor="let f of question.falseAnswerMedia" [file]="f" [removable]="true" [multiple]="false" (removed)="onRemoveImgFalseAnswer(question, f)">
            <ngx-dropzone-label>{{ f.name }}</ngx-dropzone-label>
          </ngx-dropzone-image-preview>
        </div>
      </div>
    </div>
    <div class="compta">
      <div class="button">
        <input 
          type="checkbox" 
          id="compta" 
          name="compta" 
          (change)="toggleCompta($event, i)"
          [(ngModel)]="question.countScore"
        >
        <label for="compta">Comptabiliser les points</label>
      </div>
      <div class="score" *ngIf="question.countScore">
        <div class="input">
          <input
            type="number"
            placeholder="0"
            [(ngModel)]="question.score"
          />
        </div>
        <div class="title">
          points
        </div>
      </div>
    </div>
    <!-- <div class="key">
      <div class="button">
        <input 
          type="checkbox" 
          id="key" 
          name="key" 
          (change)="toggleKey($event, i)"
          [(ngModel)]="question.key"
        >
        <label for="key">Attribuer une clé</label>
      </div>
    </div>
    <div class="letter">
      <div class="button">
        <input 
          type="checkbox" 
          id="hasLetter" 
          name="hasLetter" 
          (change)="toggleLetter($event, i)"
          [(ngModel)]="question.hasLetter"
        >
        <label for="hasLetter">Attribuer une lettre</label>
      </div>
      <div class="score" *ngIf="question.hasLetter">
        <div class="input">
          <input
            type="text"
            maxlength="1"
            [(ngModel)]="question.letter"
          />
        </div>
      </div>
    </div> -->
  </div>
</div>


<div class="add-question" (click)="onAddQuestion()">
  Ajouter une question
</div>