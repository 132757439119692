<div class="create-circuit">
  <div class="loader" *ngIf="isLoading">
    <app-loading-spinner></app-loading-spinner>
  </div>
  <div class="header-create">
    <div class="title">
      {{ pageTitle }}
    </div>
    <div class="subtitle">
      Étape 1 / 4
    </div>
  </div>
  <div class="breadcrumb">
    <li>
      <a class="active">
        Information sur le circuit >
      </a>
    </li>
    <li>
      <a (click)="createRoute(title, 2, description, youtube)" [ngClass]="{'disabled' : !title || !description}">
        Ajoutez vos points >
      </a>
    </li>
    <li>
      <a (click)="createRoute(title, 3, description, youtube)" [ngClass]="{'disabled' : !activePointsAndVariations.length}">
        Configurez vos points >
      </a>
    </li>
    <li>
      <a (click)="createRoute(title, 4, description, youtube)" [ngClass]="{'disabled' : !activePointsAndVariations.length || !checkAllPointsConfigured}">
        Validation
      </a>
    </li>
  </div>
  <div class="content">
    <div class="champs">
      <div class="explication">
        Vous pouvez enregistrer l'ensemble des informations de votre parcours sur cet écran.
        <br>
        Vous n'avez pas toutes les informations ? Pas de problème, vous pourrez y revenir !
      </div>
      <div class="form">
        <div class="input">
          <input
            type="text"
            placeholder="Nom du circuit *"
            [(ngModel)]="title"
            />
        </div>
        <editor
          [(ngModel)]="description"
          modelEvents="change input nodechange undo redo"
          apiKey="qu12q7xdexme5s8titxj7jsxytv1ycxye4bejzoo1gvh0ppg"
          [init]="{
            menubar: false,
            inline: true,
            placeholder: 'Description *',
            plugins: [
              'autolink link quickbars'
            ],
            toolbar: false,
            entity_encoding : 'raw',
            quickbars_selection_toolbar: 'bold quicklink',
            forced_root_block : ''
          }"
        >
        </editor>
        <div class="medias">
          <div class="title">
            Images de la bannière du haut
          </div>
          <div class="subtitle">
            Préférez des images rectangulaires d'une largeur minimal de 1000px
          </div>
          <div class="custom-dropzone" ngx-dropzone [accept]="'image/*'" (change)="onSelect($event)">
          	<ngx-dropzone-label>
          		<div class="label">Glissez ou cliquez pour déposer des images</div>
          	</ngx-dropzone-label>
          	<ngx-dropzone-image-preview ngProjectAs="ngx-dropzone-preview" *ngFor="let f of files" [file]="f" [removable]="true" (removed)="onRemove(f)">
              <ngx-dropzone-label>
                <span>{{ f.name }}</span>
                <div class="edit" (click)="onCropImg(f);$event.stopPropagation()">
                  <svg width="22px" height="22px" viewBox="0 0 22 22" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                    <g id="Icons" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                      <g id="Two-Tone" transform="translate(-577.000000, -2725.000000)">
                        <g id="Image" transform="translate(100.000000, 2626.000000)">
                          <g id="Two-Tone-/-Image-/-crop" transform="translate(476.000000, 98.000000)">
                            <g>
                              <polygon id="Path" points="0 0 24 0 24 24 0 24"></polygon>
                              <path d="M5,17 C5,18.1 5.9,19 7,19 L17,19 L17,23 L19,23 L19,19 L23,19 L23,17 L7,17 L7,1 L5,1 L5,5 L1,5 L1,7 L5,7 L5,17 Z M19,15 L19,7 C19,5.9 18.1,5 17,5 L9,5 L9,7 L17,7 L17,15 L19,15 Z" fill="#FFF"></path>
                            </g>
                          </g>
                        </g>
                      </g>
                    </g>
                  </svg>
                </div>
              </ngx-dropzone-label>
          	</ngx-dropzone-image-preview>
          </div>
        </div>
        <div class="infos-complementaires">
          <div class="title">
            Informations complémentaires
          </div>
          <div class="subtitle">
            Vous pourrez remplir ces informations lors de la validation du parcours
          </div>
          <div class="input">
            <input
              type="text"
              placeholder="Thème"
              [(ngModel)]="theme"
              />
          </div>
          <div class="input">
            <input
              type="text"
              placeholder="Distance du circuit"
              [(ngModel)]="distance"
              />
          </div>
          <div class="input">
            <input
              type="text"
              placeholder="Durée du circuit"
              [(ngModel)]="time"
              />
          </div>
          <div class="input">
            <input
              type="text"
              placeholder="Contenus"
              [(ngModel)]="content"
              />
          </div>
        </div>
        <div class="video">
          <div class="title">
            Vidéo de la bannière
          </div>
          <div class="subtitle">
            Vous pouvez ajouter une vidéo youtube qui se lance au clic sur la bannière. Cette option est totalement facultative.
          </div>
          <input
            type="text"
            placeholder="ID de la vidéo youtube"
            [(ngModel)]="youtube"
            />
        </div>
      </div>
    </div>
    <div class="previsualisation">
      <div class="previ">
        PRÉVISUALISATION
      </div>
      <div class="text">
        Prévisualisation en temps reel
      </div>
    </div>
  </div>
  <div class="boutons-change">
    <div class="bouton-next">
      <a (click)="createRoute(title, 2, description, youtube)" [ngClass]="{'disabled' : !title || !description}">
        Suivant >>
      </a>
    </div>
  </div>
  <div class="cropper" *ngIf="cropImg">
    <app-img-cropper (fileCropped)="onCroppedImg($event)" [img]="imgToCrop" [isModal]="true"></app-img-cropper>
  </div>
</div>
