import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { ApiService } from '../../../services/api.service';
import { CommonService } from '../../../services/common.service';
import { Observable, Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { SortablejsOptions } from 'ngx-sortablejs';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-step-two',
  templateUrl: './step-two.component.html',
  styleUrls: ['./step-two.component.scss']
})
export class StepTwoComponent implements OnInit {

  // userID = (JSON.parse(localStorage.getItem('userData'))).id;
  userID = '1';

  isLoading = false;
  pageTitle: string;
  checkAllPointsConfigured = false;
  modalNewPoint: boolean = false;
  modalNewVariation: boolean = false;
  modalExplanations: boolean = false;

  messageSubscription: Subscription;

  routeId = JSON.parse(localStorage.getItem('currentRouteId'));
  currentRoute: any;
  currentRoutePoints: any = [];
  activePointsAndVariations: any = [];
  routeVariations: any = [];
  showPoints: boolean = true;

  pointPermission: boolean = true;
  editExistingPoint: boolean = false;
  editingFromList: boolean = false;

  allPoints: any;
  allPointsFiltered: any;
  selectedPointFiltered: number = 0;
  showDropdownInSaved: boolean = false;
  inputSelected:boolean = false;

  newLatLonTriggered: boolean = false;

  newLat: string;
  newLon: string;
  newLatLonTitle: string;

  searchInSaved: string;
  searchInAddPoint: string;

  map: google.maps.Map;
  markers: any = [];
  placesService: google.maps.places.PlacesService;
  directionsService: google.maps.DirectionsService;
  directionsRenderer: google.maps.DirectionsRenderer;
  autocomplete: google.maps.places.Autocomplete;
  travelMode: string;
  bounds: google.maps.LatLngBounds;
  markersStar: any = [];
  markersVariation: any = [];

  debounce: number = 0;

  starIcon = {
    url: "../../../../assets/images/marker-map.png",
    scaledSize: new google.maps.Size(60, 60),
    labelOrigin: new google.maps.Point(30, 27)
  };
  startDragStar: google.maps.LatLng;

  variationIcon = {
    url: "../../../../assets/images/variation.png",
    scaledSize: new google.maps.Size(25, 25)
  };

  variationValidateIcon = {
    url: "../../../../assets/images/marker-map.png",
    scaledSize: new google.maps.Size(60, 60),
    labelOrigin: new google.maps.Point(30, 27)
  };

  options: SortablejsOptions = {};

  constructor(private commonService: CommonService, private apiService: ApiService, private router: Router, private toastr: ToastrService, private changeDetectorRef: ChangeDetectorRef) {
    this.options = {
      onUpdate: (event: any) => {
        this.reorderPoints();
      }
    };

    setInterval(() => {
      this.changeDetectorRef.detectChanges();
    }, 1000);
  }

  ngOnInit(): void {
    this.isLoading = true;
    this.getAllPoints();
    this.apiService.getRoute(this.routeId).subscribe(
      data => {
        this.currentRoute = data.data;

        this.pageTitle = 'Édition du circuit ' + this.currentRoute.title;
        this.currentRoutePoints = this.currentRoute.points;
        this.activePointsAndVariations = [...this.currentRoute.points, ...this.currentRoute.variations];    

        switch (this.currentRoute.trace) {
          case '0':
            this.travelMode = 'NONE';
            break;
          case '1':
            this.travelMode = 'WALKING';
            break;
          case '2':
            this.travelMode = 'BICYCLING';
            break;
          case '3':
            this.travelMode = 'DRIVING';
            break;
          default:
            this.travelMode = 'WALKING';
            break;
        }

        this.setActivePoints();
        this.calculateAndDisplayRoute(this.activePointsAndVariations);

        this.isLoading = false;
      },
      err => {
        console.log(err);
      }
    );

    this.initMap();
    this.initAutocomplete();

    this.messageSubscription = this.commonService.currentMessage.subscribe(
      message => {
        if (message == 'point saved' || message == 'point updated') {
          const subscription = this.commonService.currentPoint.subscribe(      
            point => {
              this.setPoint(point);
              this.modalNewPoint = false;
              if (message == 'point saved') {
                this.toastr.success('Point ajouté');
              } else if (message == 'point updated') {
                this.toastr.success('Point modifié');
              }
            },
            err => {
              console.log(err);
            }
          ).unsubscribe();
        }
        if (message == 'variation saved' || message == 'variation updated') {
          const subscription = this.commonService.currentPoint.subscribe(      
            data => {
              this.modalNewVariation = false;
              let variation: any = data;
              if (message == 'variation saved') {
                let marker = new google.maps.Marker({
                  icon: this.variationValidateIcon,
                  position: new google.maps.LatLng(variation.lat, variation.lon),
                  draggable: true,
                });
                let infowindow = new google.maps.InfoWindow({
                  content:  '<div style="font-size: 16px; font-weight: 400">' +
                              variation.title +
                            '</div>' +
                            '<div>' +
                              variation.description +
                            '</div>'
                });
                this.markersVariation.push({element: variation, marker: marker, infowindow: infowindow});
                this.showMarkersVariation(this.map);
                this.activePointsAndVariations.push(variation);
                this.routeVariations.push(variation);
                this.toastr.success('Variation ' + variation.title + ' ajoutée');
              } else {
                let indexrouteVariations = this.routeVariations.map(function(x) {return x.id; }).indexOf(variation.id);
                this.routeVariations[indexrouteVariations] = variation;
                this.markersVariation[indexrouteVariations].infowindow = new google.maps.InfoWindow({
                  content:  '<div style="font-size: 16px; font-weight: 400">' +
                              variation.title +
                            '</div>' +
                            '<div>' +
                              variation.description +
                            '</div>'
                });

                let indexActivePointsAndVariations = this.activePointsAndVariations.map(function(x) {return x.id; }).indexOf(variation.id);
                this.activePointsAndVariations[indexActivePointsAndVariations] = variation;
                this.toastr.success('Variation ' + variation.title + ' modifiée');
              }
              this.reorderPoints();
            },
            err => {
              console.log(err);
            }
          ).unsubscribe();
        }
        if (message == 'close modal') {
          this.modalNewVariation = false;
          this.modalNewPoint = false;
        }
        this.editingFromList = false;
      },
      err => {
        console.log(err);
      }
    );
  }

  getAllPoints() {
    this.apiService.getPointsLight().subscribe(
      data => {
        this.allPoints = data.data;
      },
      err => {
        console.log(err);
      }
    );
  }

  initMap() {
    this.directionsService = new google.maps.DirectionsService();
    this.directionsRenderer = new google.maps.DirectionsRenderer({
      suppressMarkers : true
    });

    this.map = new google.maps.Map(document.getElementById("map"), {
      center: new google.maps.LatLng(48.866667, 2.333333),
      zoom: 15
    });

    let infoWindow = new google.maps.InfoWindow({
      content: "Placeholder"
    });
    this.map.addListener("click", (mapsMouseEvent) => {
      infoWindow.close();
      infoWindow = new google.maps.InfoWindow({
        position: mapsMouseEvent.latLng,
      });
      let latLon = mapsMouseEvent.latLng.toJSON();
      infoWindow.setContent(
        "Latitude : " + latLon.lat +
        "<br>" +
        "Longitude : " + latLon.lng +
        "<br>" +
        "<button id='addPointMap' style='display: flex;width: 100%;justify-content: center;align-items: center;margin-top: 5px;cursor: pointer;'>Ajouter ce point</button>"
      );
      infoWindow.open(this.map);

      google.maps.event.addListener(infoWindow, 'domready', () => {
        const el = document.getElementById('addPointMap');
        el.addEventListener('click', (event) => {
          this.newLat = String(latLon.lat);
          this.newLon = String(latLon.lng);
          this.newLatLonTriggered = true;
          infoWindow.close();
        });
      });

      const el = document.querySelector('button');
      el.addEventListener('click', (event) => {
        this.newLat = String(latLon.lat);
        this.newLon = String(latLon.lng);
        this.newLatLonTriggered = true;
        infoWindow.close();
      });
    });

    this.directionsRenderer.setMap(this.map);
    if (this.activePointsAndVariations.length) {
      if (this.activePointsAndVariations.length > 1) {
        this.calculateAndDisplayRoute(this.activePointsAndVariations);
      }
    }
  }

  initAutocomplete() {
    this.autocomplete = new google.maps.places.Autocomplete(
      document.getElementById("autocomplete") as HTMLInputElement
    );
    let autocomplete = this.autocomplete;
    autocomplete.addListener("place_changed", () => {
      const place = autocomplete.getPlace();
      let city = '';
      place.address_components.forEach(element => {
        if (element.types.includes('locality')) {
          city = element.long_name || element.short_name;
        }
      });
      let title = place.name;
      let lat = place.geometry.location.lat();
      let lon = place.geometry.location.lng();
      let place_id = place.place_id;
      let description = place.formatted_address;
      this.addPoint(lat, lon, title, place_id, description, city);
      this.searchInAddPoint = '';
    });
  }

  onSearchInSaved(value: string, event?) {
    if (value) {
      this.showDropdownInSaved = true;
      this.allPointsFiltered = this.allPoints.filter(el => el.title.toLowerCase().includes(value.toLowerCase()));
      this.allPointsFiltered = this.allPointsFiltered.filter((point, index, self) =>
        index === self.findIndex((p) => (
          p.googleplace_id === point.googleplace_id
        ))
      );
      if (event) {
        if (event.key === 'ArrowDown') {
          if (this.selectedPointFiltered < this.allPointsFiltered.length && this.selectedPointFiltered < 5) {
            this.selectedPointFiltered = this.selectedPointFiltered + 1;
          }
          event.preventDefault();
        }
        if (event.key === 'ArrowUp') {
          if (this.selectedPointFiltered > 0) {
            this.selectedPointFiltered = this.selectedPointFiltered - 1;
          }
          event.preventDefault();
        }
        if (event.key === 'Enter') {
          this.newPointFromSaved(this.allPointsFiltered[this.selectedPointFiltered - 1]);
        }
      }
    } else {
      this.selectedPointFiltered = 0;
      this.showDropdownInSaved = false;
      this.allPointsFiltered = [];
    }
  }

  onFocus() {
    this.inputSelected = true;
  }

  onBlur() {
    setTimeout( () => this.inputSelected = false, 100 );
  }

  keepFocus(event) {
    if (event.key === 'ArrowDown') {
      event.preventDefault();
    }
    if (event.key === 'ArrowUp') {
      event.preventDefault();
    }
  }

  newPointFromSaved(point) {
    this.searchInSaved = '';
    this.selectedPointFiltered = 0;
    this.showDropdownInSaved = false;
    this.allPointsFiltered = [];
    this.isLoading = true;
    this.editExistingPoint = true;
    this.apiService.getPoint(point.id).subscribe(
      data => {
        this.isLoading = false;
        if (data.data.utilisateur_id == this.userID) {
          this.pointPermission = true;
        } else {
          this.pointPermission = false
        }
        let point = data.data;
        this.commonService.changePoint(point);
        this.modalNewPoint = true;
      }
    )
  }

  triggerNewLatLon() {
    this.newLatLonTriggered = true;
  }
  closeNewLatLon() {
    this.newLat = '';
    this.newLon = '';
    this.newLatLonTitle = '';
    this.newLatLonTriggered = false;
  }

  newPointFromLatLon() {
    this.addPoint(this.newLat, this.newLon, this.newLatLonTitle, '', '', '');
    this.closeNewLatLon();
  }

  setActivePoints() {
    if (this.activePointsAndVariations.length) {
      this.activePointsAndVariations.forEach((element) => {
        if (element.point_id) {
          this.routeVariations.push(element);
        }
      });
      this.activePointsAndVariations.sort(function(a, b){
        let orderA;
        let orderB;
        (a.pivot) ? orderA = a.pivot.order : orderA = a.order;
        (b.pivot) ? orderB = b.pivot.order : orderB = b.order;
        return orderA - orderB;
      });
      this.initMarkers();
    }
  }

  initMarkers() {
    this.activePointsAndVariations.forEach((element, index) => {
      if (element.point_id) {
        this.addMarkerVariation(element, new google.maps.LatLng(element.lat, element.lon), String(index + 1));
      } else {
        this.addMarkerStar(new google.maps.LatLng(element.lat, element.lon), element.title, element.description, String(index + 1));
      }
    });
  }

  addPoint(lat, lon, title, place_id, description, city) {
    this.apiService.addPoint(
      lat, 
      lon, 
      title, 
      { route_id: this.routeId, 
        googleplace_id: place_id, 
        description: description, 
        etat: '2',
        order: this.activePointsAndVariations.length + 1,
        city: city
      })
      .subscribe(      
      data => {
        if (data.data.create) {
          let point = {
            id: data.data.point_id,
            lat: lat,
            lon: lon,
            title: title,
            place_id: place_id,
            description: description
          };
          this.pointPermission = true;
          this.editExistingPoint = false;
          this.commonService.changePoint(point);
          this.modalNewPoint = true;
        } else {
          this.editExistingPoint = true;
          this.apiService.getPoint(data.data.point_id)      
          .subscribe(      
            data => {
              if (data.data.utilisateur_id == this.userID) {
                this.pointPermission = true;
              } else {
                this.pointPermission = false
              }
              let point = data.data;
              this.commonService.changePoint(point);
              this.modalNewPoint = true;
            },
            err => {
              console.log(err);
            }
          );
        }
      },
      err => {
        console.log(err);
      }
    );
  }

  editPoint(i) {
    this.editingFromList = true;
    this.commonService.changePoint(this.activePointsAndVariations[i]);
    this.editExistingPoint = true;
    if (this.activePointsAndVariations[i].utilisateur_id == this.userID) {
      this.pointPermission = true;
    } else {
      this.pointPermission = false
    }
    this.modalNewPoint = true;
  }

  editVariation(i) {
    this.editingFromList = true;
    if (this.activePointsAndVariations[i].utilisateur_id == this.userID) {
      this.pointPermission = true;
    } else {
      this.pointPermission = false
    }
    this.commonService.changePoint(this.activePointsAndVariations[i]);
    this.modalNewVariation = true;
  }

  setPoint(point) {
    this.apiService.getPoint(point.id).subscribe(      
      data => {
        let variations = data.data.variations;
        if (variations) {
          variations.forEach(variation => {
            if (!(this.activePointsAndVariations.some( el => el['id'] === variation.id )) && !(this.routeVariations.some( el => el['id'] === variation.id ))) {             
              this.routeVariations.push(variation);
              this.addMarkerVariation(variation, new google.maps.LatLng(variation.lat, variation.lon));
            }
          });
        }
      },
      err => {
        console.log(err);
      }
    );

    let indexArrayActive = this.activePointsAndVariations.map(function(x) {return x.id; }).indexOf(point.id);
    if (indexArrayActive !== -1) {
      this.activePointsAndVariations[indexArrayActive] = point;
    } else {
      this.activePointsAndVariations.push(point);
    }

    let indexArrayPublications = this.currentRoutePoints.map(function(x) {return x.id; }).indexOf(point.id);
    if (indexArrayPublications !== -1) {
      this.currentRoutePoints[indexArrayPublications] = point;
      this.markersStar[indexArrayPublications].infowindow = new google.maps.InfoWindow({
        content:  '<div style="font-size: 16px; font-weight: 400">' +
                    point.title +
                  '</div>' +
                  '<div>' +
                    point.description +
                  '</div>'
      });
    } else {
      this.currentRoutePoints.push(point);
      this.addMarkerStar(new google.maps.LatLng(point.lat, point.lon), point.title, point.description);
    }

    this.reorderPoints();

  }

  onRemovePoint(id) {
    let point = this.activePointsAndVariations.find(el => el.id == id);
    let variationToRemove = null;
    let pointToRemove = null;
    this.activePointsAndVariations = this.activePointsAndVariations.filter(function(el) { return el.id != id; });

    if (point.point_id) {
      pointToRemove = point.point_id;
      variationToRemove = point.id;
      this.removeMarker(point.id, 'variation');
    } else {
      pointToRemove = point.id;
      this.removeMarker(point.id, 'point');
    }

    this.apiService.unlinkPoint(pointToRemove, {variation_id: variationToRemove, route_id: this.routeId}).subscribe(
      data => {
        this.reorderPoints();
        this.toastr.success('Point supprimé');
      },
      err => {
        console.log(err);
      }
    );
  }

  removeMarker(id, type) {
    if (type == 'variation') {
      let index = this.routeVariations.map(function(x) {return x.id; }).indexOf(id);
      this.markersVariation[index].marker.setIcon(this.variationIcon);
    } else if (type == 'point') {
      let index = this.currentRoutePoints.map(function(x) {return x.id; }).indexOf(id);
      this.markersStar[index].marker.setMap(null);
      this.markersStar.splice(index, 1);
      this.currentRoutePoints.splice(index, 1);  
    }
  }

  reorderPoints() {
    const points = [];
    this.activePointsAndVariations.forEach((element, index) => {
      if (element.order !== index + 1) {
        if (element.point_id) {
          let point = {point_id: element.point_id, variation_id: element.id, order: index + 1};
          points.push(point);
        } else {
          let point = {point_id: element.id, variation_id: null, order: index + 1};
          points.push(point);
        }
      }
    });
    if (points.length) {
      this.calculateAndDisplayRoute(this.activePointsAndVariations, true);
      this.apiService.orderRoutePoints(this.routeId, points).subscribe(
        data => {
        },
        err => {
          console.log(err);
        }
      );
    }
    this.markersStar.forEach((marker, index) => {
      this.markersStar[index].marker.setMap(null)
    });
    this.markersStar = [];
    this.markersVariation.forEach((marker, index) => {
      this.markersVariation[index].marker.setMap(null);
    });
    this.markersVariation = [];

    this.activePointsAndVariations.forEach((element, index) => {
      if (element.point_id) {
        this.addMarkerVariation(element, new google.maps.LatLng(element.lat, element.lon), String(index + 1));
      } else {
        this.addMarkerStar(new google.maps.LatLng(element.lat, element.lon), element.title, element.description, String(index + 1));
      }
    });
  }

  addMarkerStar(position, title, description, order?) {
    let marker = new google.maps.Marker({
      icon: this.starIcon,
      position: position,
      label: {
        text: order,
        fontSize: '11px'
      },
      draggable: true,
    });
    let infowindow = new google.maps.InfoWindow({
      content:  '<div style="font-size: 16px; font-weight: 400">' +
                  title +
                '</div>' +
                '<div>' +
                  description +
                '</div>'
    });
    this.markersStar.push({marker: marker, infowindow: infowindow});
    this.showMarkersStar(this.map);
  }

  showMarkersStar(map: google.maps.Map) {
    this.bounds = new google.maps.LatLngBounds();
    for (let i = 0; i < this.markersStar.length; i++) {
      this.markersStar[i].marker.setMap(map);
      this.bounds.extend(this.markersStar[i].marker.getPosition());
      this.markersStar[i].marker.addListener("click", (element) => {
        this.displayInfoWindowStar(i);
      });
      this.markersStar[i].marker.addListener("dragstart", (evt) => {
        this.startDragStar = new google.maps.LatLng(evt.latLng.lat(), evt.latLng.lng());
      });
      this.markersStar[i].marker.addListener("dragend", (evt) => {
        this.createVariation(this.currentRoutePoints[i], evt.latLng.lat(), evt.latLng.lng());
        this.markersStar[i].marker.setPosition(this.startDragStar);
      });
    }
    map.fitBounds(this.bounds);
  }

  displayInfoWindowStar(index) {
    this.markersStar.forEach((marker, index) => {
      marker.infowindow.close();
    });
    this.markersStar[index].infowindow.open(this.map, this.markersStar[index].marker);
  }

  addMarkerVariation(element, position, order?) {
    let icon = this.variationIcon;
    if (this.activePointsAndVariations.some( el => el['id'] === element.id )) {
      icon = this.variationValidateIcon;
    }
    let marker = new google.maps.Marker({
      icon: icon,
      position: position,
      label: {
        text: order,
        fontSize: '11px'
      },
      draggable: true,
    });
    let infowindow = new google.maps.InfoWindow({
      content:  '<div style="font-size: 16px; font-weight: 400">' +
                  element.title +
                '</div>' +
                '<div>' +
                  element.description +
                '</div>'
    });
    this.markersVariation.push({element: element, marker: marker, infowindow: infowindow});
    this.showMarkersVariation(this.map);
  }

  showMarkersVariation(map) {
    for (let i = 0; i < this.markersVariation.length; i++) {
      this.markersVariation[i].marker.setMap(map);
      this.markersVariation[i].marker.addListener("mouseover", () => {
        this.displayInfoWindowVariation(i);
      });
      this.markersVariation[i].marker.addListener("mouseout", () => {
        this.markersVariation[i].infowindow.close();
      });
      this.markersVariation[i].marker.addListener("click", () => {
        if (!(this.activePointsAndVariations.some( el => el['id'] === this.markersVariation[i].element.id ))) {
          if (this.debounce >= (Date.now() - 20)) return;
          this.debounce = Date.now();
          this.setVariation(this.markersVariation[i]);
        }
      });
      this.markersVariation[i].marker.addListener("dragend", (evt) => {
        if (this.debounce >= (Date.now() - 20)) return;
        this.debounce = Date.now();
        if (this.markersVariation[i].element.utilisateur_id == this.userID) {
          let variation = this.markersVariation[i];
          variation.element.lat = String(evt.latLng.lat());
          variation.element.lon = String(evt.latLng.lng());
          this.apiService.addVariation(variation.element.point_id, variation.element.title, variation.element.lat, variation.element.lon, {variation_id: variation.element.id, route_id: this.routeId})
          .subscribe(
            data => {
            },
            err => {
              console.log(err);
            }
          );
        }
      });
    }
  }

  setVariation(variation) {
    this.apiService.addVariation(variation.element.point_id, variation.element.title, variation.element.lat, variation.element.lon, {variation_id: variation.element.id, route_id: this.routeId}).subscribe(
      data => {
        this.activePointsAndVariations.push(variation.element);
        this.reorderPoints();
        this.toastr.success('Variation ' + variation.element.title + ' ajoutée');
        variation.marker.setIcon(this.variationValidateIcon);
      },
      err => {
        console.log(err);
      }
    );
  }

  displayInfoWindowVariation(index) {
    this.markersVariation.forEach((marker, index) => {
      marker.infowindow.close();
    });
    this.markersVariation[index].infowindow.open(this.map, this.markersVariation[index].marker);
  }

  openModalExplanations() {
    this.modalExplanations = true;
  }

  closeModalExplanations(event) {
    this.modalExplanations = false;
  }

  createVariation(point, lat, lon) {
    if (this.debounce >= (Date.now() - 20)) return;
    this.debounce = Date.now();

    point.newVariationLat = lat;
    point.newVariationLon = lon;
    this.pointPermission = true;
    this.editingFromList = false;
    this.commonService.changePoint(point);
    this.modalNewVariation = true;
  }

  calculateAndDisplayRoute(routePoints, noWarning?) {
    if (this.travelMode == 'NONE') routePoints = null;
    let directionsService: google.maps.DirectionsService = this.directionsService;
    let directionsRenderer: google.maps.DirectionsRenderer = this.directionsRenderer;
    if (!routePoints) {
      directionsRenderer.setMap(null);
      return;
    } else {
      let origin;
      let destination;
      let waypoints = [];

      routePoints.forEach((element, index) => {
        if (index == 0) {
          origin = new google.maps.LatLng(element.lat, element.lon);
        } else if (index == routePoints.length - 1) {
          destination = new google.maps.LatLng(element.lat, element.lon);
        } else {
          waypoints.push({location: new google.maps.LatLng(element.lat, element.lon)})
        }
      });

      let request = {
        origin: origin,
        destination: destination,
        waypoints: waypoints,
        optimizeWaypoints: false,
        travelMode: google.maps.TravelMode[this.travelMode]
      };
      directionsService.route(
        request,
        (response, status) => {
          if (status == 'OK') {
            directionsRenderer.setMap(this.map);
            directionsRenderer.setDirections(response);
          } else {
            directionsRenderer.setMap(null);
            if (!noWarning) {
              this.toastr.warning('Aucun itinéraire trouvé');
            }
          }
        }
      );
    }
  }

  changeTravelMode(mode) {
    this.travelMode = mode;
    if (mode == 'NONE') {
      this.calculateAndDisplayRoute(null);
    } else {
      this.calculateAndDisplayRoute(this.activePointsAndVariations);
    }
  }

  checkIfAllPointsConfigured() {
    this.checkAllPointsConfigured = true;
    this.activePointsAndVariations.forEach((element, index) => {
      if (!element.title || !element.description) {
        this.checkAllPointsConfigured = false;
      }
    });
  }

  ngOnDestroy() {
    this.messageSubscription.unsubscribe();

    let trace: string;
    switch (this.travelMode) {
      case 'NONE':
        trace = '0';
        break;
      case 'WALKING':
        trace = '1';
        break;
      case 'BICYCLING':
        trace = '2';
        break;
      case 'DRIVING':
        trace = '3';
        break;
    }
    this.apiService.addRoute(
      this.currentRoute.title,
      {
        route_id: this.currentRoute.id, 
        description: this.currentRoute.description,
        youtube: this.currentRoute.youtube, 
        etat: this.currentRoute.etat,
        theme: this.currentRoute.theme,
        time: this.currentRoute.time,
        distance: this.currentRoute.distance,
        content: this.currentRoute.content,
        trace: trace,
        blocking_geolocation: this.currentRoute.blocking_geolocation,
        start_step_blocking_geolocation: this.currentRoute.start_step_blocking_geolocation,
        end_step_blocking_geolocation: this.currentRoute.end_step_blocking_geolocation
      }
    ).subscribe(
      data => {
      },
      err => {
        console.log(err);
      }
    );
  }

}