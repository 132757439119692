<div class="page">
  <div class="loader" *ngIf="isLoading">
    <app-loading-spinner></app-loading-spinner>
  </div>
  <div class="header-page">
    <div class="title">
      Menu
    </div>
    <div class="add-menu">
      <div (click)="onAddMenu()">
        Ajouter un élément de menu
      </div>
    </div>
  </div>
  <div class="new-menu" *ngIf="newMenuTriggered">
    <div class="sur-head">
      <div class="close" (click)="closeNewMenu()">
        <svg height="329pt" viewBox="0 0 329.26933 329" width="329pt" xmlns="http://www.w3.org/2000/svg"><path d="m194.800781 164.769531 128.210938-128.214843c8.34375-8.339844 8.34375-21.824219 0-30.164063-8.339844-8.339844-21.824219-8.339844-30.164063 0l-128.214844 128.214844-128.210937-128.214844c-8.34375-8.339844-21.824219-8.339844-30.164063 0-8.34375 8.339844-8.34375 21.824219 0 30.164063l128.210938 128.214843-128.210938 128.214844c-8.34375 8.339844-8.34375 21.824219 0 30.164063 4.15625 4.160156 9.621094 6.25 15.082032 6.25 5.460937 0 10.921875-2.089844 15.082031-6.25l128.210937-128.214844 128.214844 128.214844c4.160156 4.160156 9.621094 6.25 15.082032 6.25 5.460937 0 10.921874-2.089844 15.082031-6.25 8.34375-8.339844 8.34375-21.824219 0-30.164063zm0 0"/>
        </svg>
      </div>
    </div>
    <form #registerForm="ngForm" (ngSubmit)='onRegister(registerForm)'>
      <div class="form-group Input">
        <label for="named" class="Input-label">Titre</label>
        <input
          type="named"
          id="named"
          class="form-control Input-text"
          ngModel
          name="name"
          required
          placeholder="Titre"
          />
      </div>
      <div class="form-group Input">
        <label for="url" class="Input-label">Url</label>
        <input
          type="text"
          id="url"
          class="form-control Input-text"
          ngModel
          name="url"
          required
          placeholder="Url"
          />
      </div>
      <div class="form-group checkbox">
        <label for="inapp" class="Input-label">Ouvrir le lien dans l'app</label>
        <input
          type="checkbox"
          id="inapp"
          name="inapp"
          [(ngModel)]="newMenuInAppChecked"
          />
      </div>
      <div class="form-validate">
        <button
          class="btn btn-primary"
          type="submit"
          disabled
          [disabled]="!registerForm.valid"
          >
          Ajouter
        </button>
      </div>
    </form>
  </div>
  <div class="menu-items">
    <div class="items" *ngIf="menuItems && menuItems.length" [sortablejs]="menuItems" [sortablejsOptions]="options">
      <div *ngFor="let item of menuItems; let i = index">
        <div class="single-item">
          <div class="index">
            {{ i + 1 }}.
          </div>
          <div class="item">
            <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
              <g>
                <g>
                  <path d="M507.353,245.245l-83.692-78.769c-4.289-4.039-10.57-5.141-15.98-2.803c-5.409,2.337-8.911,7.665-8.911,13.558v34.462
                    h-98.462v-98.462h34.462c5.892,0,11.221-3.502,13.558-8.911c2.337-5.409,1.236-11.69-2.803-15.98L266.755,4.647
                    C263.964,1.682,260.072,0,256,0s-7.964,1.682-10.755,4.647L166.476,88.34c-4.039,4.29-5.14,10.571-2.803,15.98
                    c2.337,5.409,7.665,8.911,13.558,8.911h34.462v98.462h-98.462v-34.462c0-5.892-3.502-11.221-8.911-13.558
                    c-5.41-2.337-11.69-1.236-15.98,2.803L4.647,245.245C1.682,248.036,0,251.928,0,256c0,4.072,1.682,7.964,4.647,10.755
                    l83.692,78.769c4.29,4.039,10.57,5.141,15.98,2.803c5.409-2.337,8.911-7.665,8.911-13.558v-34.462h98.462v98.462h-34.462
                    c-5.892,0-11.221,3.502-13.558,8.911c-2.337,5.409-1.236,11.69,2.803,15.98l78.769,83.692c2.79,2.966,6.683,4.647,10.755,4.647
                    c4.072,0,7.964-1.682,10.755-4.647l78.769-83.692c4.039-4.29,5.14-10.571,2.803-15.98c-2.337-5.409-7.665-8.911-13.558-8.911
                    h-34.462v-98.462h98.462v34.462c0,5.892,3.502,11.221,8.911,13.558c5.41,2.337,11.691,1.236,15.98-2.803l83.692-78.769
                    c2.966-2.79,4.647-6.683,4.647-10.755S510.318,248.036,507.353,245.245z"/>
                </g>
              </g>
            </svg>
            <div class="item-content">
              <div class="item-title">
                {{ item.name }}
              </div>
              <div class="item-url">
                {{ item.url }}
              </div>
            </div>
            <div class="info-item" (click)="editItem(i)">
              <svg _ngcontent-ana-c52="" height="401pt" viewBox="0 -1 401.52289 401" width="401pt" xmlns="http://www.w3.org/2000/svg"><path _ngcontent-ana-c52="" d="m370.589844 250.972656c-5.523438 0-10 4.476563-10 10v88.789063c-.019532 16.5625-13.4375 29.984375-30 30h-280.589844c-16.5625-.015625-29.980469-13.4375-30-30v-260.589844c.019531-16.558594 13.4375-29.980469 30-30h88.789062c5.523438 0 10-4.476563 10-10 0-5.519531-4.476562-10-10-10h-88.789062c-27.601562.03125-49.96875 22.398437-50 50v260.59375c.03125 27.601563 22.398438 49.96875 50 50h280.589844c27.601562-.03125 49.96875-22.398437 50-50v-88.792969c0-5.523437-4.476563-10-10-10zm0 0"></path><path _ngcontent-ana-c52="" d="m376.628906 13.441406c-17.574218-17.574218-46.066406-17.574218-63.640625 0l-178.40625 178.40625c-1.222656 1.222656-2.105469 2.738282-2.566406 4.402344l-23.460937 84.699219c-.964844 3.472656.015624 7.191406 2.5625 9.742187 2.550781 2.546875 6.269531 3.527344 9.742187 2.566406l84.699219-23.464843c1.664062-.460938 3.179687-1.34375 4.402344-2.566407l178.402343-178.410156c17.546875-17.585937 17.546875-46.054687 0-63.640625zm-220.257812 184.90625 146.011718-146.015625 47.089844 47.089844-146.015625 146.015625zm-9.40625 18.875 37.621094 37.625-52.039063 14.417969zm227.257812-142.546875-10.605468 10.605469-47.09375-47.09375 10.609374-10.605469c9.761719-9.761719 25.589844-9.761719 35.351563 0l11.738281 11.734375c9.746094 9.773438 9.746094 25.589844 0 35.359375zm0 0"></path></svg>
            </div>
            <div class="remove" (click)="onRemoveItem(item.id)">
              <svg height="329pt" viewBox="0 0 329.26933 329" width="329pt" xmlns="http://www.w3.org/2000/svg"><path d="m194.800781 164.769531 128.210938-128.214843c8.34375-8.339844 8.34375-21.824219 0-30.164063-8.339844-8.339844-21.824219-8.339844-30.164063 0l-128.214844 128.214844-128.210937-128.214844c-8.34375-8.339844-21.824219-8.339844-30.164063 0-8.34375 8.339844-8.34375 21.824219 0 30.164063l128.210938 128.214843-128.210938 128.214844c-8.34375 8.339844-8.34375 21.824219 0 30.164063 4.15625 4.160156 9.621094 6.25 15.082032 6.25 5.460937 0 10.921875-2.089844 15.082031-6.25l128.210937-128.214844 128.214844 128.214844c4.160156 4.160156 9.621094 6.25 15.082032 6.25 5.460937 0 10.921874-2.089844 15.082031-6.25 8.34375-8.339844 8.34375-21.824219 0-30.164063zm0 0"/>
              </svg>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="edit-item" *ngIf="editItemOpen">
  <div class="content">
    <div class="sur-head">
      <div class="close" (click)="closeEditItem()">
        <svg height="329pt" viewBox="0 0 329.26933 329" width="329pt" xmlns="http://www.w3.org/2000/svg"><path d="m194.800781 164.769531 128.210938-128.214843c8.34375-8.339844 8.34375-21.824219 0-30.164063-8.339844-8.339844-21.824219-8.339844-30.164063 0l-128.214844 128.214844-128.210937-128.214844c-8.34375-8.339844-21.824219-8.339844-30.164063 0-8.34375 8.339844-8.34375 21.824219 0 30.164063l128.210938 128.214843-128.210938 128.214844c-8.34375 8.339844-8.34375 21.824219 0 30.164063 4.15625 4.160156 9.621094 6.25 15.082032 6.25 5.460937 0 10.921875-2.089844 15.082031-6.25l128.210937-128.214844 128.214844 128.214844c4.160156 4.160156 9.621094 6.25 15.082032 6.25 5.460937 0 10.921874-2.089844 15.082031-6.25 8.34375-8.339844 8.34375-21.824219 0-30.164063zm0 0"/>
        </svg>
      </div>
    </div>
    <form #editForm="ngForm" (ngSubmit)='onEdit(editForm)'>
      <div class="form-group Input">
        <label for="named" class="Input-label">Titre</label>
        <input
          type="named"
          id="named"
          class="form-control Input-text"
          [(ngModel)]="editMenuTitle"
          name="name"
          required
          placeholder="Titre"
          />
      </div>
      <div class="form-group Input">
        <label for="url" class="Input-label">Url</label>
        <input
          type="text"
          id="url"
          class="form-control Input-text"
          [(ngModel)]="editMenuUrl"
          name="url"
          required
          placeholder="Url"
          />
      </div>
      <div class="form-group checkbox">
        <label for="inapp" class="Input-label">Ouvrir le lien dans l'app</label>
        <input
          type="checkbox"
          id="inapp"
          name="inapp"
          [(ngModel)]="editMenuInAppChecked"
          />
      </div>
      <div class="form-validate">
        <button
          class="btn btn-primary"
          type="submit"
          disabled
          [disabled]="!editForm.valid"
          >
          Modifier
        </button>
      </div>
    </form>
  </div>
</div>