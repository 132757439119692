import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ApiService } from '../../services/api.service';
import { Observable, Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/services/common.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-circuits',
  templateUrl: './circuits.component.html',
  styleUrls: ['./circuits.component.scss']
})
export class CircuitsComponent implements OnInit {

  userRoutes: any;
  userRoutesFiltered: any;
  isLoading: boolean = false;
  deleteModal: boolean = false;
  searchValue: string;
  filteredByPublished: boolean = false;
  filteredByDraft: boolean = false;

  showDuplicate: boolean = false;
  duplicatingId: any;
  duplicatingTitle: string;

  messageSubscription: Subscription;

  page: number = 1;
  collection: any[]; 

  constructor(private commonService: CommonService, private apiService: ApiService, private router: Router, private toastr: ToastrService) {
  }

  ngOnInit(): void {
    this.isLoading = true;
    this.apiService.getRoutes().subscribe(
      data => {
        this.userRoutes = data.data.reverse();
        this.userRoutes.forEach(element => {
          const notations = [];
          element.trajet_notations.forEach(notation => {
            notations.push(notation.note);
          });
          let moyenne = (notations.reduce((partialSum, a) => partialSum + a, 0)) / notations.length;
          if (moyenne) element.note_moyenne = moyenne.toFixed(2);
          element.title_formatted = element.title.replace(/\s/g, '_').normalize('NFD').replace(/[^\w]/g, '').replace(/_/g, '-');
          element.app_link = environment.baseUrl + 'route/' + element.id + '/' + element.title_formatted;
        });
        this.userRoutesFiltered = this.userRoutes;
        this.isLoading = false;
      },
      err => {
        console.log(err);
      }
    );

    this.messageSubscription = this.commonService.currentMessageDuplicate.subscribe(
      message => {
        if (message == 'close modal') {
          this.showDuplicate = false;
        }
      },
      err => {
        console.log(err);
      }
    );
  }

  createNewRoute() {
    localStorage.removeItem('currentRouteId');
    this.router.navigate(['/create-circuit-1']);
  }

  onEdit(event, id) {
    if (event) {
      if (event.target.tagName.toLowerCase() === 'svg' || event.target.tagName.toLowerCase() === 'path') {
        return;
      }
    }
    localStorage.setItem('currentRouteId', id);
    this.router.navigate(['/create-circuit-1']);
  }

  onEditButton(id) {
    localStorage.setItem('currentRouteId', id);
    this.router.navigate(['/create-circuit-1']);
  }

  onPublish(id) {
    this.isLoading = true;
    let route = this.userRoutes.find(el => el.id === id);
    this.apiService.addRoute(route.title, {route_id: route.id, youtube: route.youtube, trace: route.trace, theme: route.theme, time: route.time, distance: route.distance, content: route.content, etat: '2'}).subscribe(
      data => {
        this.reloadRoutes('Parcours publié');
      },
      err => {
        console.log(err);
      }
    );
  }

  onDraft(id) {
    this.isLoading = true;
    let route = this.userRoutes.find(el => el.id === id);
    this.apiService.addRoute(route.title, {route_id: route.id, youtube: route.youtube, trace: route.trace, theme: route.theme, time: route.time, distance: route.distance, content: route.content, etat: '1'}).subscribe(
      data => {
        this.reloadRoutes('Parcours dépublié');
      },
      err => {
        console.log(err);
      }
    );
  }

  onQROnly(id) {
    this.isLoading = true;
    let route = this.userRoutes.find(el => el.id === id);
    this.apiService.addRoute(route.title, {route_id: route.id, youtube: route.youtube, trace: route.trace, theme: route.theme, time: route.time, distance: route.distance, content: route.content, etat: '3'}).subscribe(
      data => {
        this.reloadRoutes('Parcours uniquement accessible via QR Code');
      },
      err => {
        console.log(err);
      }
    );
  }

  onDelete(id) {
    if(window.confirm('Êtes-vous sûr de vouloir supprimer ce parcours ?\nCette action est irréversible.')){
      this.isLoading = true;
      let route = this.userRoutes.find(el => el.id === id);
      this.apiService.addRoute(route.title, {route_id: route.id, etat: '0'}).subscribe(
        data => {
          this.reloadRoutes('Parcous supprimé');
        },
        err => {
          console.log(err);
        }
      );
    }
  }

  onViewStats(id) {
    let title = this.userRoutes.find(x => x.id == id).title;
    title = title.replace(/\s/g, '_').normalize('NFD').replace(/[^\w]/g, '').replace(/_/g, '-');
    this.router.navigate(['/statistiques', '/route/' + id + '/' + title]);
  }

  reloadRoutes(toaster) {
    this.apiService.getRoutes().subscribe(
      data => {
        this.userRoutes = data.data;
        this.userRoutesFiltered = this.userRoutes;
        this.isLoading = false;
        this.toastr.success(toaster);
      },
      err => {
        console.log(err);
      }
    );
  }

  onSearchChange(searchValue: string) {
    this.searchValue = searchValue;
    if (this.filteredByPublished || this.filteredByDraft) {
      let filter = '';
      if (this.filteredByDraft) filter = '1';
      if (this.filteredByPublished) filter = '2';
      this.userRoutesFiltered = this.userRoutes.filter(el => el.etat == filter);
      if (searchValue) {
        this.userRoutesFiltered = this.userRoutesFiltered.filter(el => el.title.toLowerCase().includes(searchValue.toLowerCase()));
      } else {
        this.userRoutesFiltered = this.userRoutesFiltered;
      }
    } else {
      if (searchValue) {
        this.userRoutesFiltered = this.userRoutes.filter(el => el.title.toLowerCase().includes(searchValue.toLowerCase()));
      } else {
        this.userRoutesFiltered = this.userRoutes;
      }
    }
  }

  filterPublished() {
    this.filteredByDraft = false;
    if (this.filteredByPublished) {
      if (this.searchValue) {
        this.userRoutesFiltered = this.userRoutes.filter(el => el.title.toLowerCase().includes(this.searchValue.toLowerCase()));
      } else {
        this.userRoutesFiltered = this.userRoutes;
      }
      this.filteredByPublished = false;
    } else {
      if (this.searchValue) {
        this.userRoutesFiltered = this.userRoutes.filter(el => el.title.toLowerCase().includes(this.searchValue.toLowerCase()));
        this.userRoutesFiltered = this.userRoutesFiltered.filter(el => el.etat == '2');
      } else {
        this.userRoutesFiltered = this.userRoutes.filter(el => el.etat == '2');
      }
      this.filteredByPublished = true;
    }
  }

  filterDraft() {
    this.filteredByPublished = false;
    if (this.filteredByDraft) {
      if (this.searchValue) {
        this.userRoutesFiltered = this.userRoutes.filter(el => el.title.toLowerCase().includes(this.searchValue.toLowerCase()));
      } else {
        this.userRoutesFiltered = this.userRoutes;
      }
      this.filteredByDraft = false;
    } else {
      if (this.searchValue) {
        this.userRoutesFiltered = this.userRoutes.filter(el => el.title.toLowerCase().includes(this.searchValue.toLowerCase()));
        this.userRoutesFiltered = this.userRoutesFiltered.filter(el => el.etat == '1');
      } else {
        this.userRoutesFiltered = this.userRoutes.filter(el => el.etat == '1');
      }
      this.filteredByDraft = true;
    }
  }

  onShowDuplicate(id, title) {
    this.showDuplicate = true;
    this.duplicatingId = id;
    this.duplicatingTitle = title;
  }

  onPageChange(event) {
    this.page = event;
    const top = document.querySelector(".filter");
    if (top) top.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }

  onDownloadQR(i) {
    let qrcode = document.querySelector('#qrcode-' + i + ' .qrCode img').getAttribute('src');
    var a = document.createElement("a");
    a.href = qrcode;
    a.download = "parcours-qrcode.png";
    a.click();
  }

}
