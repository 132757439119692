<div class="authentication">
  <div class="loader" *ngIf="isLoading">
    <app-loading-spinner></app-loading-spinner>
  </div>
  <div class="authentication-container">
    <div class="logo">
      <img src="assets/logo_charlevoix_white.png">
    </div>
    <div class="alert alert-danger" *ngIf="error">
      <span>{{ error }}</span>
    </div>
    <!-- <div class="toggle-forms">
      <div class="login-on" (click)="toggleLogin()" [ngClass]="{'active' : formLogin}">
        Connexion
      </div>
      <div class="register-on" (click)="toggleRegister()" [ngClass]="{'active' : formRegister}">
        Inscription
      </div>
    </div> -->
    <form #loginForm="ngForm" (ngSubmit)='onLogin(loginForm)' *ngIf="formLogin">
      <div class="form-group Input">
        <label for="login" class="Input-label">Email</label>
        <input
          type="login"
          id="login"
          class="form-control Input-text"
          ngModel
          email
          name="login"
          required
          placeholder="Email"
          />
      </div>
      <div class="form-group Input">
        <label for="password" class="Input-label">Mot de passe</label>
        <input
          type="password"
          id="password"
          class="form-control Input-text"
          ngModel
          name="password"
          required
          placeholder="Mot de passe"
          />
      </div>
      <div class="form-validate">
        <button
          class="btn btn-primary"
          type="submit"
          disabled
          [disabled]="!loginForm.valid"
          >
          Se connecter
        </button>
      </div>
    </form>
    <form #registerForm="ngForm" (ngSubmit)='onRegister(registerForm)' *ngIf="formRegister">
      <div class="form-group Input">
        <label for="firstname" class="Input-label">Prénom</label>
        <input
          type="firstname"
          id="firstname"
          class="form-control Input-text"
          ngModel
          name="firstname"
          required
          placeholder="Prénom"
          />
      </div>
      <div class="form-group Input">
        <label for="named" class="Input-label">Nom</label>
        <input
          type="named"
          id="named"
          class="form-control Input-text"
          ngModel
          name="name"
          required
          placeholder="Nom"
          />
      </div>
      <div class="form-group Input">
        <label for="email" class="Input-label">Email</label>
        <input
          type="email"
          id="email"
          class="form-control Input-email"
          ngModel
          email
          name="email"
          required
          placeholder="Email"
          />
      </div>
      <div class="form-group Input">
        <label for="password" class="Input-label">Mot de passe</label>
        <input
          type="password"
          id="password"
          class="form-control Input-text"
          ngModel
          name="password"
          required
          placeholder="Mot de passe"
          />
      </div>
      <div class="form-validate">
        <button
          class="btn btn-primary"
          type="submit"
          disabled
          [disabled]="!registerForm.valid"
          >
          Créer mon compte
        </button>
      </div>
    </form>
  </div>
</div>
