import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from 'src/app/services/api.service';
import { SortablejsOptions } from 'ngx-sortablejs';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {

  isLoading: boolean = false;
  newMenuTriggered: boolean = false;
  menuItems: any;
  newMenuInAppChecked: boolean = true;

  editItemOpen: boolean = false;

  editMenuId: string;
  editMenuTitle: string;
  editMenuUrl: string;
  editMenuInAppChecked: boolean = true;
  editMenuOrder: any;

  options: SortablejsOptions = {};

  constructor(private apiService: ApiService, private toastr: ToastrService) { 
    this.options = {
      onUpdate: (event: any) => {
        this.reorderItems();
      }
    };
  }

  ngOnInit(): void {
    this.getMenuItems();
  }

  getMenuItems() {
    this.isLoading = true;
    this.apiService.listMenuItem().subscribe(
      data => {
        this.menuItems = data.data;
        this.isLoading = false;        
      },
      err => {
        this.isLoading = false;
        console.log(err);
      }
    );
  }

  onAddMenu() {
    this.newMenuTriggered = true;
  }

  closeNewMenu() {
    this.newMenuTriggered = false;
  }

  onRegister(form: NgForm) {
    if (!form.valid) {
      return;
    }

    this.isLoading = true;

    this.newMenuTriggered = false;

    const name = form.value.name;
    const url = form.value.url;    
    const inapp = this.newMenuInAppChecked;
    const blank = !inapp;
    const order = this.menuItems.length + 1;

    this.apiService.addMenuItem(name, url, blank, inapp, order).subscribe(
      resData => {
        this.toastr.success('Élément de menu ajouté', '', {
          timeOut: 2000,
        });
        this.getMenuItems();
        this.newMenuInAppChecked = true;
      },
      errRes => {
        this.toastr.error('Une erreur est survenue');
        this.isLoading = false;
      }
    );

    form.reset();
  }

  editItem(i) {
    this.editMenuId = this.menuItems[i].id;
    this.editMenuTitle = this.menuItems[i].name;
    this.editMenuUrl = this.menuItems[i].url;
    (this.menuItems[i].inapp == 1) ? this.editMenuInAppChecked = true : this.editMenuInAppChecked = false;
    this.editMenuOrder = this.menuItems[i].order;
    this.editItemOpen = true;
  }

  closeEditItem() {
    this.editItemOpen = false;
  }

  onRemoveItem(id) {
    if(window.confirm('Êtes-vous sûr de vouloir supprimer cet élément de menu ?\nCette action est irréversible.')){
      this.isLoading = true;
      this.apiService.deleteMenuItem(id).subscribe(
        data => {
          this.getMenuItems();
          this.toastr.success('Élément de menu supprimé');
        },
        err => {
          console.log(err);
        }
      );
    }
  }

  onEdit(form: NgForm) {
    if (!form.valid) {
      return;
    }

    this.isLoading = true;
    this.editItemOpen = false;

    const inapp = this.editMenuInAppChecked;
    const blank = !inapp;

    this.apiService.editMenuItem(this.editMenuId, {name: this.editMenuTitle, url: this.editMenuUrl, blank: blank, inapp: inapp, order: this.editMenuOrder}).subscribe(
      resData => {
        this.toastr.success('Élément de menu modifié', '', {
          timeOut: 2000,
        });
        this.getMenuItems();
      },
      errRes => {
        this.toastr.error('Une erreur est survenue');
        this.isLoading = false;
      }
    );

    form.reset();
  }

  reorderItems() {
    this.menuItems.forEach((element, index) => {    
      var last = false;
      if (index + 1 == this.menuItems.length) {
        last = true;
      }
      this.apiService.editMenuItem(element.id, {blank: element.blank, inapp: element.inapp, order: index + 1}).subscribe(
        resData => {
          if (last) {
            this.apiService.listMenuItem().subscribe(
              data => {
                this.menuItems = data.data;      
              },
              err => {
                console.log(err);
              }
            );
          }
        },
        errRes => {
          this.toastr.error('Une erreur est survenue');
        }
      );
    });
  }

}
