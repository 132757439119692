<div class="field title-app">
  <div class="title">
    Titre de l'application
  </div>
  <div class="input">
    <input
      type="text"
      placeholder="Titre de l'application"
      [(ngModel)]="appTitle"
    />
  </div>
  <div class="description">
    Attention le titre de l'application change les titrages dans l'application et sur les balises de référencement. Cela ne changera pas le titre de l'application elle-même.
  </div>
</div>

<div class="field meta-description">
  <div class="title">
    Méta description
  </div>
  <div class="input">
    <input
      type="text"
      placeholder="Méta description"
      [(ngModel)]="metaDescription"
    />
  </div>
  <div class="description">
    Description de votre application pour les moteurs de recherche. Ne doit pas dépasser 160 caractères.
  </div>
</div>

<div class="colors">
  <div class="color" *ngFor="let color of colors">
    <div class="title">
      {{ color.title }}
    </div>
    <div class="picker">
      <input
        [value]="color.value"
        [style.background]="color.value"
        [(colorPicker)]="color.value"
      />
    </div>
  </div>
</div>

<div class="templates">
  <div class="articles">
    <div class="add" *ngIf="!templateArticles" (click)="onAddTemplate(1)">
      Ajouter un template pour les articles
    </div>
    <div class="edit-template" *ngIf="templateArticles">
      <div class="titre">
        Bannière articles
      </div>
      <div class="field title">
        <div class="title">
          Titre
        </div>
        <div class="input">
          <input
            type="text"
            placeholder="Titre"
            [(ngModel)]="templateArticles.title"
          />
        </div>
        <div class="position">
          <label for="position-title">Position du titre</label>
          <select name="position-title" id="position-title" [(ngModel)]="templateArticles.position_title">
              <option value="1">En haut à gauche</option>
              <option value="2">En haut à droite</option>
              <option value="3">Au centre</option>
              <option value="4">En bas à gauche</option>
              <option value="5">En bas à droite</option>
          </select>
        </div>
      </div>
      <div class="field subtitle">
        <div class="title">
          Sous-titre
        </div>
        <div class="input">
          <input
            type="text"
            placeholder="Sous-titre"
            [(ngModel)]="templateArticles.subtitle"
          />
        </div>
      </div>
      <div class="field bandeau">
        <div class="title">
          Bandeau
        </div>
        <div class="custom-dropzone" ngx-dropzone [accept]="'*'" maxFileSize="10000000" (change)="selectNewBandeauArticles($event)">
          <ngx-dropzone-label>
            <div class="label">Glissez ou cliquez pour déposer une image ou une courte vidéo</div>
          </ngx-dropzone-label>
          <ngx-dropzone-image-preview ngProjectAs="ngx-dropzone-preview" *ngFor="let f of newBandeauArticles" [file]="f" [removable]="true" [multiple]="false" (removed)="removeNewBandeauArticles(f)">
            <ngx-dropzone-label>{{ f.name }}</ngx-dropzone-label>
          </ngx-dropzone-image-preview>
        </div>
      </div>
      <div class="field logo">
        <div class="title">
          Logo
        </div>
        <div class="custom-dropzone" ngx-dropzone [accept]="'image/*'" (change)="selectNewLogoArticles($event)">
          <ngx-dropzone-label>
            <div class="label">Glissez ou cliquez pour déposer une image</div>
          </ngx-dropzone-label>
          <ngx-dropzone-image-preview ngProjectAs="ngx-dropzone-preview" *ngFor="let f of newLogoArticles" [file]="f" [removable]="true" [multiple]="false" (removed)="removeNewLogoArticles(f)">
            <ngx-dropzone-label>{{ f.name }}</ngx-dropzone-label>
          </ngx-dropzone-image-preview>
        </div>
        <div class="position">
          <label for="position-logo">Position du logo</label>
          <select name="position-logo" id="position-logo" [(ngModel)]="templateArticles.position_logo">
              <option value="1">En haut à gauche</option>
              <option value="2">En haut à droite</option>
              <option value="3">Au centre</option>
              <option value="4">En bas à gauche</option>
              <option value="5">En bas à droite</option>
          </select>
        </div>
      </div>
    </div>
  </div>
  <div class="trajets">
    <div class="add" *ngIf="!templateTrajets" (click)="onAddTemplate(2)">
      Ajouter un template pour les circuits
    </div>
    <div class="edit-template" *ngIf="templateTrajets">
      <div class="titre">
        Bannière parcours
      </div>
      <div class="field title">
        <div class="title">
          Titre
        </div>
        <div class="input">
          <input
            type="text"
            placeholder="Titre"
            [(ngModel)]="templateTrajets.title"
          />
        </div>
        <div class="position">
          <label for="position-title">Position du titre</label>
          <select name="position-title" id="position-title" [(ngModel)]="templateTrajets.position_title">
              <option value="1">En haut à gauche</option>
              <option value="2">En haut à droite</option>
              <option value="3">Au centre</option>
              <option value="4">En bas à gauche</option>
              <option value="5">En bas à droite</option>
          </select>
        </div>
      </div>
      <div class="field subtitle">
        <div class="title">
          Sous-titre
        </div>
        <div class="input">
          <input
            type="text"
            placeholder="Sous-titre"
            [(ngModel)]="templateTrajets.subtitle"
          />
        </div>
      </div>
      <div class="field bandeau">
        <div class="title">
          Bandeau
        </div>
        <div class="custom-dropzone" ngx-dropzone [accept]="'*'" maxFileSize="10000000" (change)="selectNewBandeauTrajets($event)">
          <ngx-dropzone-label>
            <div class="label">Glissez ou cliquez pour déposer une image ou une courte vidéo</div>
          </ngx-dropzone-label>
          <ngx-dropzone-image-preview ngProjectAs="ngx-dropzone-preview" *ngFor="let f of newBandeauTrajets" [file]="f" [removable]="true" [multiple]="false" (removed)="removeNewBandeauTrajets(f)">
            <ngx-dropzone-label>{{ f.name }}</ngx-dropzone-label>
          </ngx-dropzone-image-preview>
        </div>
      </div>
      <div class="field logo">
        <div class="title">
          Logo
        </div>
        <div class="custom-dropzone" ngx-dropzone [accept]="'image/*'" (change)="selectNewLogoTrajets($event)">
          <ngx-dropzone-label>
            <div class="label">Glissez ou cliquez pour déposer une image</div>
          </ngx-dropzone-label>
          <ngx-dropzone-image-preview ngProjectAs="ngx-dropzone-preview" *ngFor="let f of newLogoTrajets" [file]="f" [removable]="true" [multiple]="false" (removed)="removeNewLogoTrajets(f)">
            <ngx-dropzone-label>{{ f.name }}</ngx-dropzone-label>
          </ngx-dropzone-image-preview>
        </div>
        <div class="position">
          <label for="position-logo">Position du logo</label>
          <select name="position-logo" id="position-logo" [(ngModel)]="templateTrajets.position_logo">
              <option value="1">En haut à gauche</option>
              <option value="2">En haut à droite</option>
              <option value="3">Au centre</option>
              <option value="4">En bas à gauche</option>
              <option value="5">En bas à droite</option>
          </select>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="save">
  <div class="bouton" (click)="onSave()">
    Enregistrer
  </div>
</div>